<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí Study Plan phần sản phẩm {{ category_title }}
      </h1>
    </div>

    <v-row class="pt-5 pl-5 pr-5">
      <v-col lg="9" md="12" style="margin: 0 auto">
        <template v-for="(level_study_plan, iLS) in level_study_plans">
          <v-row :key="iLS" class="mb-10">
            <v-col cols="2"></v-col>
            <v-col cols="7" class="text-left pl-0 pr-0">
              <span class="text-h5"
                >Level {{ iLS + 1 }}: Trình độ {{ level_study_plan.name }}</span
              >
            </v-col>
            <v-col cols="2" class="text-right pl-0 pr-0">
              <v-btn
                @click="
                  openDialogStudyPlan(
                    level_study_plan.id,
                    level_study_plan.study_plans.length
                  )
                "
                color="success"
                outlined
                >Thêm</v-btn
              >
            </v-col>
            <v-col cols="1"></v-col>

            <template v-for="(study_plan, iS) in level_study_plan.study_plans">
              <v-col cols="12" :key="iS" class="mt-1">
                <v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="2" class="text-right">
                    <v-icon
                      class="cursor-pointer"
                      @click="openDialogSort(level_study_plan.study_plans)"
                      >mdi-format-line-spacing</v-icon
                    >
                  </v-col>
                  <v-col cols="8" class="pt-0 pr-0">
                    <div
                      class="study-plan-item d-flex justify-content-between align-center"
                    >
                      <router-link
                        :to="{
                          name: 'StudyPlanUnit',
                          params: { id: study_plan.id },
                        }"
                        target="_blank"
                      >
                        <span class="text-h6 ml-2 cursor-pointer"
                          >Study Plan {{ iS + 1 }}: {{ study_plan.name }}</span
                        >
                        <span
                          v-if="
                            getLabelStatusByValue(study_plan.status) == 'On Air'
                          "
                          style="background: #0bb7af2e"
                          class="label label-lg label-inline font-weight-bold ml-1 py-4 teal--text"
                        >
                          {{ getLabelStatusByValue(study_plan.status) }}
                        </span>
                        <span
                          v-if="
                            getLabelStatusByValue(study_plan.status) ==
                            'Editing'
                          "
                          style="background: #424242"
                          class="label label-lg label-inline font-weight-bold ml-1 py-4 white--text"
                        >
                          {{ getLabelStatusByValue(study_plan.status) }}
                        </span>
                      </router-link>
                      <v-btn
                        @click="editStudyPlan(level_study_plan.id, study_plan)"
                        class="text-right"
                        style="background: white"
                        color="warning"
                        outlined
                        >Sửa</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <StudyPlanForm
      :show_dialog="dialogStudyPlan"
      :type_form="type_form"
      :category_id="category_id"
      :level_id="level_id"
      :status_plan="status_study_plan"
      :new_offset="new_offset"
      :study_plan="study_plan"
      @setDialog="(e) => (this.dialogStudyPlan = e)"
      @resetData="getStudyPlanByCategoryId()"
    />

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortStudyPlan"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortStudyPlan = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css cursor-pointer"
                      v-for="(study_plan, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ study_plan.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="sortStudyPlan"
              >Update</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="dialogSortStudyPlan = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import StudyPlanForm from "./modal/StudyPlanForm";
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
  name: "StudyPlanList",
  components: {
    StudyPlanForm,
    Loading: () => import("vue-loading-overlay"),
    draggable,
  },
  data() {
    return {
      is_call_api: false,
      category_title: "",
      level_study_plans: [],
      status_study_plan: [],
      dialogStudyPlan: false,
      type_form: null,
      level_id: null,
      new_offset: null,
      study_plan: {},
      dialogSortStudyPlan: false,
      data_draggable: [],
    };
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getStudyPlanByCategoryId();
    this.getStatusStudyPlan();
  },
  methods: {
    async getStudyPlanByCategoryId() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/study-plan?category_id=" + this.category_id
        );
        if (res.status === 200) {
          vm.category_title = res.data.category;
          vm.level_study_plans = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getStatusStudyPlan() {
      let vm = this;
      await ApiService.get("prep-app/study-plan/get-status").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.status_study_plan = response.data;
        }
      });
    },
    getLabelStatusByValue(value) {
      if (this.status_study_plan.length <= 0) {
        return "";
      }
      let data_status = this.status_study_plan.filter(
        (e) => e.value == value
      )[0];
      return data_status.label;
    },
    openDialogStudyPlan(level_id, study_plan_length) {
      this.type_form = "add";
      this.new_offset = study_plan_length + 1;
      this.level_id = level_id.toString();
      this.dialogStudyPlan = true;
    },
    editStudyPlan(level_id, study_plan) {
      this.study_plan = JSON.parse(JSON.stringify(study_plan));
      this.level_id = level_id.toString();
      this.type_form = "update";
      this.dialogStudyPlan = true;
    },
    openDialogSort(study_plans) {
      this.data_draggable = JSON.parse(JSON.stringify(study_plans));
      this.dialogSortStudyPlan = true;
    },
    sortStudyPlan() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let data_sort = this.data_draggable.map((item, index) => {
            return {
              study_plan_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/study-plan/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getStudyPlanByCategoryId();
                vm.$toasted.success("Săp xếp thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSortStudyPlan = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.study-plan-item {
  border: 1px solid #efefef;
  background-color: #efefef;
  min-height: 45px;
  border-radius: 5px;
}
</style>
